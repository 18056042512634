<template>
	<div>

    <div class="x-contact-us">
            <a href="https://lin.ee/DjaLVwH" class="-line-contact-button-container js-line-contact-hover" target="_blank" rel="noopener">
            <img src="../assets/images/ic-contact-line.png" class="-ic" alt="XXMAC รูปโลโก้ Line png">
            <div class="-content-wrapper">
                <div class="-content-inside">
                    <span>ฝ่ายบริการลูกค้า<br>ดูแลคุณ 24 ชั่วโมง</span>
                </div>
            </div>
        </a>
    </div>
    <div class="x-contact-us2">
            <a href="https://tv.xxmac.co" class="-line-contact-button-container js-line-contact-hover" target="_blank" rel="noopener">
            <img src="../assets/images/dooball.png" class="-ic" alt="XXMAC รูปโลโก้ Line png">
        </a>
    </div>
    <div id="account-actions" class="-logged" v-if="user.key">
        <div class="x-button-actions" id="account-actions-mobile">
          <div class="-outer-wrapper">
            <div class="-left-wrapper">
              <router-link to="/" class="-item-wrapper -promotion">
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/home.png"
                  />
                  <img
                    src="../assets/images/home.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">หน้าแรก</span>
              </router-link>
              <a
                href="javascript:void(0);"
                class="-item-wrapper -deposit js-account-approve-aware"
                data-toggle="modal"
                data-target="#depositModal"
                @click="checkPromotions()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icdeposit.png"
                  />
                  <img
                    src="../assets/images/icdeposit.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ฝากเงิน</span>
              </a>

            </div>

            <router-link to="/provider" class="-center-wrapper">
              <div
                class="-selected"
                style="background-image: url('https://firebasestorage.googleapis.com/v0/b/xxmac-co.appspot.com/o/images%2Fplay-64x64-xxmac.png?alt=media&token=bd2378b2-35bc-4163-a95f-fa92d073a5f9')"
              >
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-top.png"
                  class="-top-curve lazyload"
                  alt="SHIBA888 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-bottom.png"
                  class="-bottom-curve lazyload"
                  alt="SHIBA888 คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
              </div>
            </router-link>
            <div
              class="-fake-center-bg-wrapper"
              style="position: absolute;z-index: 1;width: 100%;height: 50px;overflow: hidden;left: 0;bottom: 0;"
            >
              <svg viewbox="-10 -1 30 12">
                <defs>
                  <linearGradient
                    id="rectangleGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="20%" stop-color="#393854" />
                    <stop offset="100%" stop-color="#100e1e" />
                  </linearGradient>
                </defs>
                <path
                  d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"
                />
              </svg>
            </div>

            <div class="-right-wrapper">
				      <a
                href="javascript:void(0);"
                class="-item-wrapper -withdraw js-account-approve-aware"
                data-toggle="modal"
                data-target="#withdrawModal"
                @click="moveCreditToWallet()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icwithdraw.png"
                  />
                  <img
                    src="../assets/images/icwithdraw.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ถอนเงิน</span>
              </a>
              <a
                href="https://lin.ee/DjaLVwH"
                class="-item-wrapper -line"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/shiba888-ic-mobile-left-1.png"
                  />
                  <img
                    src="../assets/images/shiba888-ic-mobile-left-1.png"
                    class="-ic-img"
                    alt="SHIBA888 รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ติดต่อเรา</span>
              </a>
            </div>
            <div class="-fully-overlay js-footer-lobby-overlay"></div>
          </div>
        </div>
      </div>

    <footer class="x-footer -anon">
        <div class="x-slot-providers-logo-component  ">

            <div class="container -providers-logo-inner">
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-bbin-slot.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-bbin-slot.png"><img alt="XXMAC wt-bbin-slot logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="100" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-bbin-slot.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-jili.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-jili.png"><img alt="XXMAC wt-jili logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="200" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-jili.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wtm-spinix.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wtm-spinix.png"><img alt="XXMAC wtm-spinix logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="300" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wtm-spinix.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-mpoker.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-mpoker.png"><img alt="XXMAC wt-mpoker logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="400" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-mpoker.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-mgp-slot.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-mgp-slot.png"><img alt="XXMAC wt-mgp-slot logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="500" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-mgp-slot.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-dragoon-soft.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-dragoon-soft.png"><img alt="XXMAC wt-dragoon-soft logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="600" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-dragoon-soft.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-fa-chai.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-fa-chai.png"><img alt="XXMAC wt-fa-chai logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="700" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-fa-chai.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-gioco-plus.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-gioco-plus.png"><img alt="XXMAC wt-gioco-plus logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="800" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-gioco-plus.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-next-spin.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-next-spin.png"><img alt="XXMAC wt-next-spin logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="900" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-next-spin.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-relax-gaming.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-relax-gaming.png"><img alt="XXMAC wt-relax-gaming logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1000" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-relax-gaming.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-rich88.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-rich88.png"><img alt="XXMAC wt-rich88 logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1100" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-rich88.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-goldy.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-goldy.png"><img alt="XXMAC wt-goldy logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1200" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-goldy.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-ameba.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-ameba.png"><img alt="XXMAC wt-ameba logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1300" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-ameba.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wtm-ygg-gaming.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wtm-ygg-gaming.png"><img alt="XXMAC wtm-ygg-gaming logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1400" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wtm-ygg-gaming.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-pragmatic-play.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-pragmatic-play.png"><img alt="XXMAC wt-pragmatic-play logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1500" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-pragmatic-play.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-play-tech.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-play-tech.png"><img alt="XXMAC wt-play-tech logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1600" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-play-tech.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-sp.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-sp.png"><img alt="XXMAC sp logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1700" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-sp.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-netent-slot.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-netent-slot.png"><img alt="XXMAC wt-netent-slot logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1800" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-netent-slot.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-red-tiger.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-red-tiger.png"><img alt="XXMAC wt-red-tiger logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="1900" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-red-tiger.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-wm-slot.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-wm-slot.png"><img alt="XXMAC wt-wm-slot logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2000" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-wm-slot.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-ps.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-ps.png"><img alt="XXMAC wt-ps logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2100" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-ps.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-kingmaker.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-kingmaker.png"><img alt="XXMAC wt-kingmaker logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2200" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-kingmaker.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-habanero.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-habanero.png"><img alt="XXMAC wt-habanero logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2300" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-habanero.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-joker.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-joker.png"><img alt="XXMAC wt-joker logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2400" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-joker.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-pg-soft.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-pg-soft.png"><img alt="XXMAC wt-pg-soft logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2500" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-pg-soft.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-cq9.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-cq9.png"><img alt="XXMAC wt-cq9 logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2600" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-cq9.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-evo-play.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-evo-play.png"><img alt="XXMAC wt-evo-play logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2700" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-evo-play.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-only-play.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-only-play.png"><img alt="XXMAC wt-only-play logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2800" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-only-play.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-kalamba.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-kalamba.png"><img alt="XXMAC wt-kalamba logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="2900" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-kalamba.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wt-just-play.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wt-just-play.png"><img alt="XXMAC wt-just-play logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="3000" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wt-just-play.png"></picture>
                    <picture><source type="image/webp" srcset="../assets/images/common/logo-horizontal-dark-wtm-endorphina.png"><source type="image/png" srcset="../assets/images/common/logo-horizontal-dark-wtm-endorphina.png"><img alt="XXMAC wtm-endorphina logo png" class="-img img-fluid animated fadeInUp" width="85" height="32" data-delay="3100" data-animatable="fadeInUp" src="../assets/images/common/logo-horizontal-dark-wtm-endorphina.png"></picture>
            </div>
    </div>

    <div class="-inner-wrapper lazyloaded">
        <div class="container -image-wrapper">
            <img src="../assets/images/logo.png" alt="ez-bet" class="img-fluid -img">

            <div class="static-text">
              <p class="-sub-text" style="margin-top: 0;">สล็อตออนไลน์ เจ้าแรกในไทย ให้บริการผ่านเว็บตรง ที่มีเกมสล็อตมากกว่า 1,000 เกมกับ 21ค่ายชื่อดัง รวมเกมแตกบ่อย ที่หลายเว็บไม่มีให้เล่น  ทุกอย่างครบจบภายในกระเป๋าเดียว ไม่ต้องโยกเงินให้เสียเวลา ระบบอัตโนมัติทุกขั้นตอน สมัครสมาชิก ,ฝาก -ถอนไว ใช้เวลาทำธุรกรรมเพียง 3 วินาที  รับฟรีสิทธิพิเศษสำหรับลูกค้า สูตรสล็อต สูตรบาคาร่า ดูหนังออนไลน์ ไม่มีค่าใช้จ่ายเพิ่มเติม เล่นได้ทุกที่ สนุกได้ทุกเวลา XXMAC</p>
            </div>

            <div class="js-replace-footer-slide-seo-container">
<!--
        <div data-slickable="{&quot;dots&quot;:true,&quot;fade&quot;:true,&quot;arrows&quot;:false,&quot;autoplay&quot;:true,&quot;infinite&quot;:true,&quot;pauseOnHover&quot;:false,&quot;adaptiveHeight&quot;:true,&quot;slidesToShow&quot;:1,&quot;autoplaySpeed&quot;:4000}" class="slick-initialized slick-slider slick-dotted">
                            <div class="slick-list draggable" style="height: 104px;"><div class="slick-track" style="opacity: 1; width: 5700px;"><div class="slick-slide" data-slick-index="0" aria-hidden="true" role="tabpanel" id="slick-slide50" style="width: 1140px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" aria-describedby="slick-slide-control50" tabindex="-1">
                            <h2 class="-text">สกิลเกม เจ้าแรกในไทย</h2>
                        <p class="-sub-text"><span class="-highlight">XXMAC</span> Slot Online FullHD รองรับ App iOS, Android สล็อต คาสิโน ยิงปลา และกีฬา ครบจบ เว็บเดียวจากค่ายดังทุกมุมโลก  EzSlot.bet มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวยใหม่ และ เป็นที่นิยม เรารวม สล็อตออนไลน์ และ เกมสล็อต มากที่สุดในไทย  ด้วยระบบเติมถอนอัจฉริยะ เล่นง่าย รวดเร็ว ฝากถอนออโต้ เจ้าเดียวที่ใช้ได้จริง</p>
        </div>

        <div class="slick-slide slick-current slick-active" data-slick-index="1" aria-hidden="false" role="tabpanel" id="slick-slide51" style="width: 1140px; position: relative; left: -1140px; top: 0px; z-index: 999; opacity: 1;" aria-describedby="slick-slide-control51">
                            <h3 class="-text">สล็อตออนไลน์ เว็บตรงอันดับ 1 ของประเทศไทย</h3>
                        <p class="-sub-text">สล็อตออนไลน์ เจ้าแรกในไทย ให้บริการผ่านเว็บตรง ที่มีเกมสล็อตมากกว่า 1,000 เกมกับ 21ค่ายชื่อดัง รวมเกมแตกบ่อย ที่หลายเว็บไม่มีให้เล่น  ทุกอย่างครบจบภายในกระเป๋าเดียว ไม่ต้องโยกเงินให้เสียเวลา ระบบอัตโนมัติทุกขั้นตอน สมัครสมาชิก ,ฝาก -ถอนไว ใช้เวลาทำธุรกรรมเพียง 3 วินาที  รับฟรีสิทธิพิเศษสำหรับลูกค้า สูตรสล็อต สูตรบาคาร่า ดูหนังออนไลน์ ไม่มีค่าใช้จ่ายเพิ่มเติม เล่นได้ทุกที่ สนุกได้ทุกเวลา ezslot</p>
        </div>

        <div class="slick-slide" data-slick-index="2" aria-hidden="true" role="tabpanel" id="slick-slide52" style="width: 1140px; position: relative; left: -2280px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" aria-describedby="slick-slide-control52" tabindex="-1">
                            <h3 class="-text">คาสิโนออนไลน์สด ชั้นนำระดับโลก</h3>
                        <p class="-sub-text">คาสิโนออนไลน์สด เสมือนกับอยู่ในคาสิโนจริง มีรูปแบบการเดิมพันมากมายให้เลือกเล่นอาทิเช่น บาคาร่า ,เสือมังกร ,รูเล็ต ,ไฮโล  และอื่นๆอีกมากมาย ทำกำไรง่าย ได้เงินจริงกับเว็บตรงที่มีนักเดิมพันเข้าใช้งานเยอะที่สุดในประเทศไทย รองรับทุกระบบทั้งคอม และมือถือ  ปรับไม้เดิมพันได้เอง ไม่ต้องแจ้งแอดมินให้เสียเวลา เดิมพันสูงสุด 200,000ต่อไม้  ถอนได้ไม่จำกัด</p>
        </div><div class="slick-slide" data-slick-index="3" aria-hidden="true" role="tabpanel" id="slick-slide53" style="width: 1140px; position: relative; left: -3420px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" aria-describedby="slick-slide-control53" tabindex="-1">
                            <h3 class="-text">แทงบอลออนไลน์ ผ่านเว็บตรงเล่นได้บนมือถือ</h3>
                        <p class="-sub-text">แทงบอลออนไลน์ ราคาดีที่สุดในไทย เหนือกว่าทุกเจ้า เรามีให้เล่นทุกคู่ ครบทุกลีก รองรับภาษาไทย เล่นออนไลน์ได้ผ่านมือถือ ไม่มีสะดุด  ไม่ต้องโยกเงิน ฝากถอนไวใช้เวลาเพียง 3วินาที  มีโปรโมชั่นและสิทธิพิเศษสุดคุ้มสำหรับลูกค้าของ ezslot ดูบอลออนไลน์สดฟรี ไม่มีโฆษณา ภาพคมชัดระดับHD</p>
        </div><div class="slick-slide" data-slick-index="4" aria-hidden="true" role="tabpanel" id="slick-slide54" style="width: 1140px; position: relative; left: -4560px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" aria-describedby="slick-slide-control54" tabindex="-1">
                            <h3 class="-text">เกมยิงปลา แตกหนักแจกจริง</h3>
                        <p class="-sub-text">เกมยิงปลา ที่มีให้เล่นมากกว่า 20เกมรวมทุกค่ายดังมาให้ท่านได้เลือกเล่น ปลาตายง่าย การเดิมพันต่ำ อัตราการเกิดแจ็กพอตสูง  เพลิดเพลินไปกับภาพเสียง และเอฟเฟคสุดอลังการ เล่นได้ทุกแพลตฟอร์ม Fishunter ที่หลายนักเดิมพันหลายคนชอบ ร่วมสนุกได้แล้ววันนี้  ผ่านระบบอัจฉริยะ อัตโนมัติทุกขั้นตอน เว็บเดิมพันที่ดีที่สุดในประเทศไทย ezslot</p>
        </div></div></div>
                </div>
            </div>
        </div>

        <div class="container position-relative">
 -->
<!-- <div class="x-contact-directly animated fadeInUp" data-animatable="fadeInUp" data-delay="100">
    <div class="-text-wrapper -empty">
        <span class="-text-top">Created website by</span>
                                <a href="https://wintech.io" class="-img-wrap -img-wrap-link text-decoration-none" target="_blank" rel="nofollow noopener">
                                    <img src="https://ezslot.bet/build/web/shared/img/button-bg-wintech-dark.png?v=1" class="-button-bg -dark" alt="WinTech company entry" width="350" height="84">
                                    <img src="https://ezslot.bet/build/web/shared/img/button-bg-wintech-light.png?v=1" class="-button-bg -light" alt="WinTech company entry" width="350" height="84">
                            </a>

            </div>
    <div class="-qr-wrapper -empty">
            </div>
            -->
</div>
</div>

<div class="container">
    <!-- <div class="-footer-menu -vertical">
        <div class="-block-provider">
            <h6 class="-text-title -provider">ค่ายเกมส์สล็อตออนไลน์</h6>
            <div class="-list-menu">
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">pgsoft</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">joker</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">jili</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">bbinslot</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">microgaming</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">evoplay</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">kingmaker</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">giocoplus</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">netentslot</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">fachai</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">nextspin</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">relaxgaming</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">rich88</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">goldy</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">ameba</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">yggdrasil</a>
                    </div>
                                    <div class="-list-item">
                        <a href="/pragmaticplay" class="-btn-link -provider">pragmaticplay</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">playtech</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">redtiger</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">simpleplay</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">isoftbet</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">wmslot</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">playstar</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">habanero</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">cq9</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">onlyplay</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">kalamba</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">justplay</a>
                    </div>
                                    <div class="-list-item">
                        <a href="javascript:void(0)" class="-btn-link -provider">dragoonsoft</a>
                    </div>
                            </div>
        </div>
        <div class="-block-menu">
            <h6 class="-text-title -menu">สารบัญหน้าเว็บ</h6>
            <div class="-list-menu">
                                    <div class="-list-item -two-column">
                        <router-link to="/" class="-btn-link">หน้าแรก</router-link>
                    </div>
                                    <div class="-list-item -two-column">
                        <router-link to="/provider" class="-btn-link">สล็อต</router-link>
                    </div>
                                    <div class="-list-item -two-column">
                        <router-link to="/casino" class="-btn-link">คาสิโน</router-link>
                    </div>
                                    <div class="-list-item -two-column">
                        <a href="/playsport" target="_blank" class="-btn-link">สปอร์ต</a>
                    </div>
                                    <div class="-list-item -two-column">
                        <router-link to="/event" class="-btn-link">สิทธิพิเศษ</router-link>
                    </div>
                                    <div class="-list-item -two-column">
                        <router-link to="/casino" class="-btn-link">โปรโมชั่น</router-link>
                    </div>
                            </div>
        </div>
        <div class="-block-tag">
            <h6 class="-text-title -tag">TAG เพิ่มเติม</h6>
<div class="-list-menu">
            <div class="-list-item">
            <router-link to="/provider" class="-btn-link">slot</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">slot online</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/" class="-btn-link">XXMAC</router-link>
        </div>
            <div class="-list-item">
            <router-link to="/provider" class="-btn-link">game slot</router-link>
        </div>
            <div class="-list-item">
            <router-link to="/provider" class="-btn-link">gameslot</router-link>
        </div>
            <div class="-list-item">
            <router-link to="/provider" class="-btn-link">สล็อต</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">สล็อตออนไลน์</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">เกมสล็อต</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/fish" class="-btn-link">ยิงปลา</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/fish" class="-btn-link">เกมยิงปลา</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">joker</router-link>
        </div>
            <div class="-list-item">
            <a href="javascript:void(0)" class="-btn-link">
                evoplay
            </a>
        </div>
            <div class="-list-item">
            <a href="javascript:void(0)" class="-btn-link">
                simpleplay
            </a>
        </div>
            <div class="-list-item">
            <a href="javascript:void(0)" class="-btn-link">
                playstar
            </a>
        </div>
            <div class="-list-item">
            <a href="javascript:void(0)" class="-btn-link">
                kingmaker
            </a>
        </div>
            <div class="-list-item">
            <a href="javascript:void(0)" class="-btn-link">
                habanero
            </a>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">slot xo</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/provider" class="-btn-link">สล็อตเครดิตฟรี</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/casino" class="-btn-link">คาสิโนสด</router-link>
        </div>
            <div class="-list-item">
                 <router-link to="/casino" class="-btn-link">พนันออนไลน์</router-link>
        </div>
            <div class="-list-item">
                <router-link to="/main/slot" class="-btn-link">พนันออนไลน์</router-link>
        </div>
    </div>

                    </div>
    </div> -->
</div>

    </div>

    <div class="text-center -copy-right-container">
                <p class="mb-0 -copy-right-text">
            Copyright XXMAC© 2022 All Rights Reserved.
        </p>
    </div>
</footer>

	</div>
</template>
<script>
import firebase from 'firebase/app'
import router from './../router'
export default {
  name: 'Footer',
  computed: {
    user () {
      return this.$store.state.user.user
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  methods: {
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    async moveCreditToWallet () {
      this.$store.commit('user/SET_LOADING', true)
      const user = this.getUserId()
      this.$store.dispatch('user/refreshCreditAll').finally(res => {
        this.$store.dispatch('user/getLastBonus', user).finally(res => {
          this.$store.commit('user/SET_LOADING', false)
          if (this.lastbonus.turncredit) {
            this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
          }
        }).catch(err => { console.error(err) })
      }).catch(err => { console.error(err); this.fa_spin = false })
    },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    }
  }
}
</script>
<style scoped>
#account-actions-mobile .-outer-wrapper {
    display: none;
}
@media (max-width: 500px) {
#account-actions-mobile .-outer-wrapper {
    display: flex;
    align-items: flex-end;
}

#account-actions-mobile .-left-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 22px;
}

#account-actions-mobile .-left-wrapper, #account-actions-mobile .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg,#000f34,#225cef);
    transition: filter .2s;
}

#account-actions-mobile .-right-wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 22px;
}

#account-actions-mobile .-left-wrapper, #account-actions-mobile .-right-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 1;
    z-index: 2;
    height: 70px;
    padding-bottom: 6px;
    background: linear-gradient(180deg,#000f34,#225cef);
    transition: filter .2s;
}

#account-actions-mobile .-center-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    width: 74px;
    height: 74px;
    margin: 0 8px 16px;
    padding-bottom: 8px;
    color: #fff;
    /* background: linear-gradient(180deg,#ffe872 25%,#ffa021); */
    /* border-radius: 50%; */
    /* box-shadow: 0 0 10px hsl(0deg 0% 100% / 40%); */
}

#account-actions-mobile .-item-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}

.static-text {
    margin-left: 8px;
    margin-right: 8px;
}
}
#account-actions-mobile .-center-wrapper .-selected {
    transition: all .4s;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: 70px;
    background-position: 3px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}
</style>
